import { v4 } from 'uuid';

import TYPES from '@/types';

// Application
import { UpdateCustomerDocumentFileCommand } from '@/modules/onboarding/customer-document/application/services/file/update';
import { VerifyCustomerDocumentBankStatementMagicLinkToken }
  from '@/modules/onboarding/customer-document/application/services/bank-statement';
import SizeTranslator from '@/modules/common/application/services/file/size-translator';

// Domain
import { DocumentTypeIdEnum }
  from '@/modules/onboarding/documents/domain/enums/document-type-id-enum';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Functions from '@/modules/shared/domain/utils/functions';

export default class UpdateBankStatementViewModel {
  @Inject(TYPES.NOTIFIER)
  private readonly notifier!: MessageNotifier;

  @Inject(TYPES.ONBOARDING_UPDATE_CUSTOMER_DOCUMENT_FILE_COMMAND)
  private readonly update_customer_document_file_command!: UpdateCustomerDocumentFileCommand;

  @Inject(TYPES.VERIFY_CUSTOMER_DOCUMENT_MAGIC_LINK_TOKEN)
  private readonly verify_customer_document_magic_link_token!:
    VerifyCustomerDocumentBankStatementMagicLinkToken;

  @Inject(TYPES.UTIL_FUNCTIONS)
  private readonly functions!: Functions;

  private readonly view!: any;

  readonly inputs_requirements = {
    bank_statement: {
      size_limit_in_mb: 4,
    },
  };

  loading = false;

  inputs: { bank_statement?: File } = {
    bank_statement: undefined,
  };

  inputs_config = {
    bank_statement: {
      accept: 'image/jpg,image/jpeg,image/png',
      size_limit: 1000 * 1024 * this.inputs_requirements.bank_statement.size_limit_in_mb,
    },
  };

  bank_statement_updated = false;

  token = '';

  is_the_second_bank_account = false;

  get can_continue() {
    return !!this.inputs.bank_statement;
  }

  public constructor(view: any) {
    this.view = view;
  }

  initialize = async (token: string) => {
    this.token = token;
    await this.loadTokenInformation();
  }

  get extra_title_information() {
    return (this.is_the_second_bank_account) ? 'para su segunda cuenta bancaria' : '';
  }

  get correct_account_text() {
    return (this.is_the_second_bank_account) ? 'o IBAN' : 'CLABE';
  }

  loadTokenInformation = async () => {
    try {
      this.loading = true;
      const { document_type_id } = await this.verify_customer_document_magic_link_token
        .execute(this.token);
      this.is_the_second_bank_account = document_type_id === DocumentTypeIdEnum
        .SECOND_BANK_STATEMENT_ID;
      this.loading = false;
    } catch {
      this.notifier.showErrorNotification('Hubo un error al cargar la información del token');
    }
  }

  selectFile = ({ target }: any) => {
    const selected_file = target.files[0];
    const valid_file_size = (
      selected_file.size <= this.inputs_config.bank_statement.size_limit
    );

    if (valid_file_size) {
      this.inputs.bank_statement = selected_file;
    } else {
      this.notifier.showErrorNotification(
        `El estado de cuenta no puede pesar más de ${SizeTranslator.bytesToMB(
          this.inputs_config.bank_statement.size_limit,
        )} Mb`,
      );
    }
  };

  updateCustomerDocumentFile = async () => {
    try {
      this.loading = true;
      const file_data = await this.functions.convert_file_to_base_64(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.inputs.bank_statement!,
      );

      await this.update_customer_document_file_command.execute({
        document_type: {
          name: 'bank-statement',
        },
        token: this.view.$route.query.token,
        file: {
          name: v4(),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          mime_type: this.inputs.bank_statement!.type,
          file_data: file_data as string,
        },
      });

      this.bank_statement_updated = true;
    } catch {
      this.notifier.showErrorNotification('Hubo un error al subir tu estado de cuenta');
    } finally {
      this.loading = false;
    }
  };
}
