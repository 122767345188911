








































































































import { Vue, Component } from 'vue-property-decorator';

import UpdateBankStatementViewModel
  from '@/vue-app/view-models/pages/update-bank-statement-view-model';
import Card from '@/vue-app/components/onboarding/Card.vue';
import PreviewDocument from '@/vue-app/components/documents/preview-document.vue';
import CustomerDocumentSuccessfullyUpdated
  from '@/vue-app/components/onboarding/customer-documents/customer-document-successfully-updated.vue';

// Application
import SizeTranslator from '@/modules/common/application/services/file/size-translator';

@Component({
  name: 'UpdateBankStatement',
  components: {
    Card,
    PreviewDocument,
    CustomerDocumentSuccessfullyUpdated,
  },
})
export default class UpdateBankStatement extends Vue {
  readonly update_bank_statement_view_model = Vue.observable(
    new UpdateBankStatementViewModel(this),
  );

  size_translator = SizeTranslator;

  created() {
    this.$store.dispatch('layout/toggleGoToSiteOnHeader', false);
  }

  async mounted() {
    if (this.$route?.query?.token) {
      await this.update_bank_statement_view_model
        .initialize(this.$route?.query?.token.toString());
    }
  }
}
